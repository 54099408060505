import { useState, useEffect, useRef } from "react";
import { Table } from "react-bootstrap";
import { BsPencilFill, BsCheckLg, BsDownload, BsShare } from "react-icons/bs";
import { savePrompt, getChatCompletion } from "../../../api/post";
import { BeatLoader } from "react-spinners";
import {
  randomStringRef,
  basicQuestions,
  generatePDF,
  isJsonString
} from "../../../service";
import PdfContent from "../../chat/pdfContent";
import CopyBtn from "../../../components/copyBtn";
import ButtonEle from "../../../components/buttonEle";
import ModalEle from "../../../components/modalEle";
import ShareChat from "../../chat/shareChat";

function Report(props: any) {
  const pdfContentRef = useRef(null);

  const [promptId, setPromptId] = useState(randomStringRef(6) + "promptV2");
  const [open, setOpen] = useState(false);

  const [copyLink, setCopyLink] = useState("");

  const [err, setErr] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (err) {
      setTimeout(() => {
        setErr("");
      }, 3000);
    }
  }, [err]);

  useEffect(() => {
    if (props.editPrompt) {
      setPromptId(props.editPrompt.id);
    }
  }, [JSON.stringify(props.editPrompt)]);

  const exportPdf = () => {
    try {
      generatePDF({
        data: pdfContentRef.current,
        fileName: "Reinvent.pdf"
      });
    } catch (error) {
      console.log(error);
    }
  };

  const save = async (event: any) => {
    event?.preventDefault();
    setErr("");

    if (props.updateSections.length === 0) {
      return;
    }

    setLoading(true);
    const res: any = await savePrompt({
      id: promptId,
      email: props.userinfo.email,
      report: JSON.stringify(props.updateSections)
    });
    if (res.data) {
      if (res.data.success) {
        if (res.data.prompt) {
          props.setEditPrompt(res.data.prompt);
          setCopyLink(`${window.location.origin}/survey/${promptId}`);
        }
      } else {
        setErr(res.data.err?.message);
      }
    } else {
      setErr(res.err?.message);
    }
    setLoading(false);
  };

  const updateSectionsEditableHandle = (
    index: number,
    value: boolean,
    flag: string
  ) => {
    let arr = [];

    for (let i = 0; i < props.updateSectionsEditable.length; i++) {
      if (index === i) {
        if (flag === "t") {
          arr.push({ t: value, p: props.updateSectionsEditable[i].p });
        } else {
          arr.push({ t: props.updateSectionsEditable[i].t, p: value });
        }
      } else {
        arr.push(props.updateSectionsEditable[i]);
      }
    }

    props.setUpdateSectionsEditable(arr);
  };

  return (
    <>
      <div className="d-none">
        <div ref={pdfContentRef}>
          <PdfContent data={props.content} />
        </div>
      </div>
      <div className="v2-edit-content">
        <div className="chat-zone d-flex justify-content-between align-items-center">
          <p className="sub-title mb-2">Report</p>
          <div className="cancel">
            <div>
              <BsShare size={16} onClick={() => setOpen(true)} />
            </div>
            <div className="mx-3">
              <BsDownload size={18} onClick={() => exportPdf()} />
            </div>
          </div>
        </div>
        <div>
          <Table>
            <tbody>
              {props.updateSections.length > 0 &&
                props.updateSections.map(
                  (item: { t: string; p: string }, index: number) => (
                    <tr key={index}>
                      <td
                        style={{
                          verticalAlign: "top",
                          paddingRight: "10px"
                        }}
                      >
                        <div
                          style={{
                            fontFamily: "Muli-Regular",

                            cursor: "pointer",
                            padding: "3px 5px"
                          }}
                        >
                          <div className="intdiv d-flex">
                            <textarea
                              className="int black"
                              style={{ fontWeight: "bold", fontSize: 18 }}
                              value={item.t}
                              rows={2}
                              onChange={(e) => {
                                let arr = [...props.updateSections];
                                arr[index].t = e.target.value;
                                props.setUpdateSections(arr);
                              }}
                              disabled={
                                props.updateSectionsEditable.length > 0 &&
                                !props.updateSectionsEditable[index].t
                              }
                            ></textarea>
                            {props.updateSectionsEditable.length > 0 &&
                            props.updateSectionsEditable[index].t ? (
                              <BsCheckLg
                                className="m-2"
                                size={20}
                                style={{ cursor: "pointer" }}
                                color="#20c997"
                                onClick={() =>
                                  updateSectionsEditableHandle(
                                    index,
                                    false,
                                    "t"
                                  )
                                }
                              />
                            ) : (
                              <BsPencilFill
                                color="rgb(86, 122, 115)"
                                style={{ cursor: "pointer" }}
                                className="m-2"
                                onClick={() =>
                                  updateSectionsEditableHandle(index, true, "t")
                                }
                              />
                            )}
                          </div>
                          <div className="intdiv d-flex">
                            <textarea
                              className="int black"
                              value={item.p}
                              rows={4}
                              onChange={(e) => {
                                let arr = [...props.updateSections];
                                arr[index].p = e.target.value;
                                props.setUpdateSections(arr);
                              }}
                              disabled={
                                props.updateSectionsEditable.length > 0 &&
                                !props.updateSectionsEditable[index].p
                              }
                            ></textarea>
                            {props.updateSectionsEditable.length > 0 &&
                            props.updateSectionsEditable[index].p ? (
                              <BsCheckLg
                                className="m-2"
                                size={20}
                                style={{ cursor: "pointer" }}
                                color="#20c997"
                                onClick={() =>
                                  updateSectionsEditableHandle(
                                    index,
                                    false,
                                    "p"
                                  )
                                }
                              />
                            ) : (
                              <BsPencilFill
                                color="rgb(86, 122, 115)"
                                style={{ cursor: "pointer" }}
                                className="m-2"
                                onClick={() =>
                                  updateSectionsEditableHandle(index, true, "p")
                                }
                              />
                            )}
                          </div>
                        </div>
                      </td>
                    </tr>
                  )
                )}
            </tbody>
          </Table>
        </div>

        {err && <p className="err">{err}</p>}

        {props.updateSections.length > 0 && (
          <div className="d-md-flex justify-content-between mt-4">
            <ButtonEle title="generate" handle={save} />
            {copyLink && (
              <CopyBtn btn={true} text="Copy link" value={copyLink} />
            )}
          </div>
        )}

        {loading && (
          <div className="loading">
            <BeatLoader color="#f42f3b" size={12} />
          </div>
        )}
      </div>
      <ModalEle
        name="shareConversation"
        title="Share the conversation"
        open={open}
        setOpen={setOpen}
        buttonTitle=""
        handle=""
      >
        <ShareChat
          userEmail={props.userinfo.email}
          filename="Reinvent.pdf"
          filetype="application/pdf"
          filecontent={props.convStr}
        />
      </ModalEle>
    </>
  );
}

export default Report;
