import { useState, useEffect } from "react";
import AccordianDiv from "../../../components/accordianDiv";
import { BsChevronDown, BsChevronRight } from "react-icons/bs";

function MessageItem(props: any) {
  return (
    <div className="accordian-div">
      <div
        className="d-flex justify-content-between cursor-pointer"
        onClick={props.updateFlag}
      >
        <p className="sub-title my-1">{props.title}</p>
        <div>
          {props.flag ? (
            <BsChevronDown size={12} color="#567a73" />
          ) : (
            <BsChevronRight size={12} color="#567a73" />
          )}
        </div>
      </div>
      <AccordianDiv flag={props.flag}>
        <div className="intdiv">
          <p className="lable">System message</p>
          <textarea
            className="int"
            value={props.sysmsg}
            onChange={(e) => {
              props.updateMsg("sys", e.target.value);
            }}
            rows={7}
          />
        </div>
        <div className="intdiv">
          <p className="lable">User message</p>
          <textarea
            className="int"
            value={props.usermsg}
            onChange={(e) => {
              props.updateMsg("user", e.target.value);
            }}
            rows={7}
          />
        </div>
      </AccordianDiv>
    </div>
  );
}

export default MessageItem;
