import { useState, useEffect } from "react";
import { BiCaretLeftCircle } from "react-icons/bi";
import { savePrompt } from "../../../api/post";
import { isJsonString } from "../../../service";
import { BeatLoader } from "react-spinners";
import MessageItem from "./messageItem";
import MsgList from "../promptDummy.json";
import ButtonEle from "../../../components/buttonEle";

function Index(props: any) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [msgList, setMsgList] = useState<any[]>([]);
  const [msgOpen, setMsgOpen] = useState<boolean[]>([]);
  const [number, setNumber] = useState(3);
  const [promptId, setPromptId] = useState("");
  const [err, setErr] = useState("");
  const [success, setSuccess] = useState("");
  const [loading, setLoading] = useState(false);

  const handle = async (event: any) => {
    event?.preventDefault();

    setErr("");
    setSuccess("");
    setLoading(true);
    const saveRes: any = await savePrompt({
      id: promptId,
      email: props.userinfo.email,
      msgList: JSON.stringify(msgList)
    });

    if (saveRes.data) {
      if (saveRes.data.success) {
        if (saveRes.data.prompt) {
          props.setEditPrompt(saveRes.data.prompt);
        }
        setSuccess("Messages are saved successfully.");
      } else {
        setErr(saveRes.data.err?.message);
      }
    }

    setLoading(false);
  };

  useEffect(() => {
    setTimeout(() => {
      setSuccess("");
    }, 4000);
  }, [err, success]);

  useEffect(() => {
    if (props.editPrompt) {
      setPromptId(props.editPrompt.id);

      const list =
        props.editPrompt.msgList &&
        isJsonString(props.editPrompt.msgList) &&
        JSON.parse(props.editPrompt.msgList).length > 0
          ? JSON.parse(props.editPrompt.msgList)
          : MsgList.msgList;

      setMsgList(list);
      setMsgOpen(Array(list.length).fill(false));
    } else {
      setMsgList(MsgList.msgList);
    }
  }, [JSON.stringify(props.editPrompt)]);

  const updateMsg = (param: string, i: number, value: string) => {
    let list = [...msgList];
    if (param === "sys") {
      list[i].sys = value;
    } else {
      list[i].user = value;
    }
    setMsgList(list);
  };

  const updateFlag = (i: number) => {
    let arr = [...msgOpen];
    arr[i] = !arr[i];
    setMsgOpen(arr);
  };

  return (
    <div>
      <div
        className="v2-edit-content d-md-flex align-items-center"
        style={{ paddingRight: 30, alignItems: "center" }}
      >
        <BiCaretLeftCircle
          size={30}
          style={{ cursor: "pointer", marginRight: 40 }}
          onClick={() => props.setState("table")}
        />
        <h2 className="title m-0">messages</h2>
      </div>
      <div className="mt-5">
        {msgList.map((item: any, index: number) => (
          <div key={index} className="mt-2">
            <MessageItem
              flag={msgOpen[index]}
              title={item.title}
              sysmsg={item.sys}
              usermsg={item.user}
              number={number}
              setNumber={setNumber}
              updateMsg={(param: string, value: string) =>
                updateMsg(param, index, value)
              }
              updateFlag={() => updateFlag(index)}
            />
          </div>
        ))}
      </div>
      {success && <p className="success">{success}</p>}
      {err && <p className="err">{err}</p>}
      <div className="mt-2 text-center">
        <ButtonEle title="Save" handle={handle} />
      </div>
      {loading && (
        <div className="loading">
          <BeatLoader color="#f42f3b" size={12} />
        </div>
      )}
    </div>
  );
}

export default Index;
