//@ts-nocheck

import React from "react";
import { Modal } from "react-bootstrap";
import { BeatLoader } from "react-spinners";
import ButtonEle from "./buttonEle";

const ModalEle = ({
  name,
  open,
  setOpen,
  title,
  handle,
  buttonTitle,
  children,
  loading = false
}) => {
  return (
    <Modal show={open} onHide={() => setOpen(false)}>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {children}
        {buttonTitle && (
          <div className="mt-2 text-center">
            <ButtonEle title={buttonTitle} handle={handle} />
          </div>
        )}
        {loading && (
          <div className="loading">
            <BeatLoader color="#f42f3b" size={12} />
          </div>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default ModalEle;
