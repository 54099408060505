import { useState, useEffect, useRef } from "react";
import CloseIcon from "../../assets/imgs/close.png";
import { getChatCompletion } from "../../api/post";
import { BeatLoader } from "react-spinners";
import CopyBtn from "../../components/copyBtn";
import { BsDownload, BsShare } from "react-icons/bs";
import { generatePDF } from "../../service";
import PdfContent from "./pdfContent";
import { Modal } from "react-bootstrap";
import ShareChat from "./shareChat";
import { Table } from "react-bootstrap";
import MsgList from "../promptV2/promptDummy.json";

function PromptChat(props: any) {
  const pdfContentRef = useRef(null);

  const [loading, setLoading] = useState(false);
  const [convStr, setConvStr] = useState("");
  const [open, setOpen] = useState(false);
  const [content, setContent] = useState<{ title: string; body: string }[]>([]);
  const [err, setErr] = useState("");

  var index = 0;

  useEffect(() => {
    const reportAnswer = async () => {
      index++;
      setErr("");
      setLoading(true);
      let sysMsgStr =
        "You're an intelligent assistant who creates reports for clients based on their assessment. Every report has sections, and every section has a title and content. You will be given a title and instruction for each section – use the instruction to create the content for the section. Use the client assessment as context:\n\nClient assessment:";

      for (let k = 0; k < props.questions.length; k++) {
        sysMsgStr += `\n\nQuestion: ${
          MsgList.symbol.includes(props.questions[k].substr(-1))
            ? props.questions[k]
            : props.questions[k] + "."
        }\nAnswer: ${
          MsgList.symbol.includes(props.answers[k].substr(-1))
            ? props.answers[k]
            : props.answers[k] + "."
        }`;
      }

      let str = "";
      let contentArr: any = [];

      for (let i = 0; i < props.sections.length; i++) {
        let usermsg = "";
        for (let k = 0; k < i; k++) {
          usermsg += `Here is section ${k + 1} of the report: ${
            contentArr[k].title
          }: ${
            MsgList.symbol.includes(contentArr[k].body.substr(-1))
              ? contentArr[k].body
              : contentArr[k].body + "."
          } `;
        }
        usermsg += `\nCreate section ${i} of the report. The section title is ${
          MsgList.symbol.includes(props.sections[i].t.substr(-1))
            ? props.sections[i].t
            : props.sections[i].t + "."
        } The section instruction is ${
          MsgList.symbol.includes(props.sections[i].p.substr(-1))
            ? props.sections[i].p
            : props.sections[i].p + "."
        }\nThe output should be ${
          MsgList.symbol.includes(props.sections[i].o.substr(-1))
            ? props.sections[i].o
            : props.sections[i].o + "."
        }\n${
          MsgList.symbol.includes(props.sections[i].t.substr(-1))
            ? props.sections[i].t
            : props.sections[i].t + "."
        }:`;

        const result: any = await getChatCompletion([
          {
            role: "system",
            content: sysMsgStr
          },
          {
            role: "user",
            content: usermsg
          }
        ]);

        if (result.data) {
          const txt = result?.data?.choices?.[0]?.message?.content.trim();
          if (txt) {
            str += `${props.sections[i].t}\n${txt}\n`;
            contentArr.push({
              title: props.sections[i].t,
              body: txt
            });
          }
        } else {
          setErr("No data");
          break;
        }
      }
      setLoading(false);
      setConvStr(str);
      setContent(contentArr);
    };

    if (
      index === 0 &&
      props.questions.length > 0 &&
      props.answers.length > 0 &&
      props.sections.length > 0
    ) {
      reportAnswer();
    } else {
      setErr("No data");
    }
  }, [
    JSON.stringify(props.questions),
    JSON.stringify(props.answers),
    JSON.stringify(props.sections)
  ]);

  const exportPdf = () => {
    try {
      generatePDF({
        data: pdfContentRef.current,
        fileName: "Reinvent.pdf"
      });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <div className="d-none">
        <div ref={pdfContentRef}>
          <PdfContent data={content} />
        </div>
      </div>

      <div className="chat-container">
        <div className="chat-zone">
          <div className="d-flex justify-content-between align-items-center">
            <div>
              <h2 className="title"></h2>
            </div>
            <div className="cancel">
              <div>
                <BsShare size={16} onClick={() => setOpen(true)} />
              </div>
              <div className="mx-3">
                <BsDownload size={18} onClick={() => exportPdf()} />
              </div>
              <div style={{ position: "relative", top: "-2px" }}>
                <CopyBtn size={16} value={convStr} />
              </div>
              {props.init && (
                <img
                  style={{ marginLeft: 20, position: "relative", top: 1 }}
                  src={CloseIcon}
                  alt="close"
                  width={20}
                  onClick={() => props.init()}
                />
              )}
            </div>
          </div>
          {content.length > 0 && <h2 className="title">{props.reportMsg}</h2>}
          {err && <p className="err">{err}</p>}
          {loading && (
            <>
              <div className="sub-title p-2">{props.interimMsg}</div>
              <div className="p-2">
                <BeatLoader color="red" size={9} />
              </div>
            </>
          )}
          <Table>
            <tbody>
              {content.length > 0 &&
                content.map(
                  (item: { title: string; body: string }, index: number) => (
                    <tr key={index}>
                      <td
                        style={{
                          verticalAlign: "top",
                          paddingRight: "10px"
                        }}
                      >
                        <h5 style={{ fontWeight: "bold" }}>{item.title}</h5>
                        <p
                          style={{ fontSize: 18 }}
                          dangerouslySetInnerHTML={{
                            __html: item.body.replace(/\n/g, "<br />")
                          }}
                        />
                      </td>
                    </tr>
                  )
                )}
            </tbody>
          </Table>
        </div>
      </div>
      <Modal show={open} onHide={() => setOpen(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Share the conversation</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ShareChat
            userEmail={props.userEmail}
            filename="Reinvent.pdf"
            filetype="application/pdf"
            filecontent={convStr}
          />
        </Modal.Body>
      </Modal>
    </>
  );
}

export default PromptChat;
