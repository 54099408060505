import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { getPromptById } from "../../api/post";
import PromptSurvey from "./promptSurvey";
import PromptChat from "./promptChat";
import { isJsonString } from "../../service";

function Chat() {
  const [state, setState] = useState("survey");
  const [questions, setQuestions] = useState<string[]>([]);
  const [sections, setSections] = useState<any[]>([]);
  const [answers, setAnswers] = useState([]);
  const [userEmail, setUserEmail] = useState("");
  const [reportMsg, setReportMsg] = useState("");
  const [interimMsg, setInterimMsg] = useState("");
  const [instruction, setInstruction] = useState(null);

  const params = useParams();

  var index = 0;

  useEffect(() => {
    const getPrompt = async () => {
      index++;
      const res = await getPromptById({ id: params.id });
      if (res.data) {
        setQuestions(
          res.data.Item.questions.S ? JSON.parse(res.data.Item.questions.S) : []
        );
        setReportMsg(
          res.data.Item.reportMsg && res.data.Item.reportMsg.S
            ? res.data.Item.reportMsg.S
            : "Thanks for completing the assessment! Here’s your customized report."
        );
        setInterimMsg(
          res.data.Item.interimMsg && res.data.Item.interimMsg.S
            ? res.data.Item.interimMsg.S
            : "Take a deep breath and exhale while your report is being generated."
        );
        setInstruction(
          res.data.Item.instruction && res.data.Item.instruction.S
            ? res.data.Item.instruction.S
            : ""
        );

        if (
          res.data.Item.basicSections &&
          isJsonString(res.data.Item.basicSections.S)
        ) {
          const sectionsArr = JSON.parse(res.data.Item.basicSections.S);
          let arr = [];
          for (let i = 0; i < sectionsArr.length; i++) {
            const t = sectionsArr[i].t ? sectionsArr[i].t : "";
            const p = sectionsArr[i].p ? sectionsArr[i].p : "";
            const o = sectionsArr[i].to
              ? sectionsArr[i].o
              : "The output of this section should be 1-2 paragraphs.";
            arr.push({ t, p, o });
          }

          setSections(arr);
        }
      }
    };

    if (index === 0 && params.id) {
      getPrompt();
    }
  }, [params.id]);

  const init = () => {
    setAnswers([]);
    setState("survey");
  };

  return (
    <div className="layout top-border" style={{ minHeight: "100vh" }}>
      <div className="sub-container px-2">
        {state === "survey" ? (
          <div className="content">
            <PromptSurvey
              setState={setState}
              questions={questions}
              answers={answers}
              setAnswers={setAnswers}
              userEmail={userEmail}
              setUserEmail={setUserEmail}
              promptId={params.id}
              instruction={instruction}
            />
          </div>
        ) : (
          <div
            className="m-auto p-2"
            style={{
              maxWidth: "1020px",
              border: "1px solid #58ceed",
              borderRadius: 8,
              backgroundColor: "rgba(255, 255, 255, 0.5)"
            }}
          >
            <PromptChat
              init={init}
              questions={questions}
              answers={answers}
              sections={sections}
              userEmail={userEmail}
              promptId={params.id}
              reportMsg={reportMsg}
              interimMsg={interimMsg}
            />
          </div>
        )}
      </div>
    </div>
  );
}

export default Chat;
