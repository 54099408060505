import { Link } from "react-router-dom";

function Footbar() {
  return (
    <div className="footbar">
      2024 Sentives, All rights reserved.{" "}
      <Link
        to="/tos"
        style={{ fontFamily: "Muli-ExtraBold", color: "rgb(2, 23, 48)" }}
      >
        Terms
      </Link>{" "}
      and{" "}
      <Link
        to="/policy"
        style={{ fontFamily: "Muli-ExtraBold", color: "rgb(2, 23, 48)" }}
      >
        Privacy
      </Link>
    </div>
  );
}

export default Footbar;
