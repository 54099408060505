//@ts-nocheck
import { useState, useEffect } from "react";

import { BiCaretLeftCircle } from "react-icons/bi";
import { isJsonString } from "../../service";

import MultiStepProgressBar from "./stepProgressBar";
import Assessment from "./edit/assessment";
import Analysis from "./edit/analysis";
import Report from "./edit/report";
import MsgList from "./promptDummy.json";

function PromptEdit(props: any) {
  const [promptName, setPromptName] = useState("");
  const [promptDescription, setPromptDescription] = useState("");
  const [number, setNumber] = useState<any>(3);
  const [o1Str, setO1Str] = useState("");
  const [assessmentStr, setAssessmentStr] = useState("");
  const [questions, setQuestions] = useState<string[]>([]);
  const [demoAnswers, setDemoAnswers] = useState<string[]>([]);
  const [convStr, setConvStr] = useState("");
  const [content, setContent] = useState<{ title: string; body: string }[]>([]);
  const [autoSection1Title, setAutoSection1Title] = useState("Intro");
  const [autoSection1Content, setAutoSection1Content] = useState(
    "Write a paragraph that provides an overview about the benefits of being a better coach"
  );
  const [updateSections, setUpdateSections] = useState<
    { t: string; p: string }[]
  >([]);
  const [updateSectionsEditable, setUpdateSectionsEditable] = useState<
    { t: boolean; p: boolean }[]
  >([]);
  const [page, setPage] = useState(1);
  const [msgList, setMsgList] = useState("[]");

  useEffect(() => {
    if (props.editPrompt) {
      setPromptName(props.editPrompt.name);
      setPromptDescription(props.editPrompt.description);
      setQuestions(
        props.editPrompt.questions && isJsonString(props.editPrompt.questions)
          ? JSON.parse(props.editPrompt.questions)
          : []
      );
      setDemoAnswers(
        props.editPrompt.demoAnswers &&
          isJsonString(props.editPrompt.demoAnswers)
          ? JSON.parse(props.editPrompt.demoAnswers)
          : []
      );
      setO1Str(
        props.editPrompt.clientAnalysis ? props.editPrompt.clientAnalysis : ""
      );
      setAssessmentStr(
        props.editPrompt.clientAnalysis
          ? props.editPrompt.clientAnalysis.split(
              "The following is the survey given to the user:"
            )[0]
          : ""
      );

      setUpdateSections(
        props.editPrompt.report && isJsonString(props.editPrompt.report)
          ? JSON.parse(props.editPrompt.report)
          : []
      );
      setUpdateSectionsEditable(
        props.editPrompt.report && isJsonString(props.editPrompt.report)
          ? Array(JSON.parse(props.editPrompt.report).length).fill({
              t: false,
              p: false
            })
          : []
      );

      let str = "";
      let contentArr: any = [];

      if (props.editPrompt.report && isJsonString(props.editPrompt.report)) {
        JSON.parse(props.editPrompt.report).forEach(function (item, index) {
          str += `${item.t}\n${item.p}\n`;
          contentArr.push({ title: item.t, body: item.p });
        });

        setConvStr(str);
        setContent(contentArr);
      }

      const list =
        props.editPrompt.msgList &&
        isJsonString(props.editPrompt.msgList) &&
        JSON.parse(props.editPrompt.msgList).length > 0
          ? props.editPrompt.msgList
          : JSON.stringify(MsgList.msgList);

      setMsgList(list);
    } else {
      setMsgList(JSON.stringify(MsgList.msgList));
    }
  }, [JSON.stringify(props.editPrompt)]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [page]);

  const updateMsg = (msg: string) => {
    let nameValue = promptName;
    if (promptName && !MsgList.symbol.includes(nameValue.substr(-1))) {
      nameValue += ".";
    }

    let descriptionValue = promptDescription;
    if (
      promptDescription &&
      !MsgList.symbol.includes(descriptionValue.substr(-1))
    ) {
      descriptionValue += ".";
    }

    let surveyQuestionStr = "";
    for (let i = 0; i < questions.length; i++) {
      surveyQuestionStr += `\n${
        MsgList.symbol.includes(questions[i].substr(-1))
          ? questions[i]
          : questions[i] + "."
      }`;
    }

    let questionsJson = [];
    for (let i = 0; i < number; i++) {
      questionsJson.push({
        question: "question text",
        answer: "answer text"
      });
    }

    let surveyStr = "";
    for (let k = 0; k < questions.length; k++) {
      surveyStr += `\nQuestion: ${
        MsgList.symbol.includes(questions[k].substr(-1))
          ? questions[k]
          : questions[k] + "."
      }\nAnswer: ${
        MsgList.symbol.includes(demoAnswers[k].substr(-1))
          ? demoAnswers[k]
          : demoAnswers[k] + "."
      }`;
    }

    let sectionsJson = [];
    for (let i = 0; i < number; i++) {
      sectionsJson.push({
        Title: "Title text",
        Instruction: "Instruction text"
      });
    }

    let str = msg;
    str = str.replace(/({tool name})/g, nameValue);
    str = str.replace(/({tool description})/g, descriptionValue);
    str = str.replace(/({tool description})/g, descriptionValue);
    str = str.replace(/({number})/g, number);
    str = str.replace(/({Questions JSON})/g, JSON.stringify(questionsJson));
    str = str.replace(/({survey questions})/g, surveyQuestionStr);
    str = str.replace(/({survey})/g, surveyStr);
    str = str.replace(/({client assessment})/g, assessmentStr);
    str = str.replace(/({auto section1 title})/g, autoSection1Title);
    str = str.replace(/({auto section1 instruction})/g, autoSection1Content);
    str = str.replace(/({Sections JSON})/g, JSON.stringify(sectionsJson));
    str = str.replace(
      /({auto section1 title})/g,
      JSON.stringify(autoSection1Title)
    );
    str = str.replace(
      /({auto section1 instruction})/g,
      JSON.stringify(autoSection1Content)
    );

    return str;
  };

  return (
    <>
      <div
        className="v2-edit-content d-md-flex"
        style={{ paddingRight: 30, alignItems: "center" }}
      >
        <div className="flex">
          <BiCaretLeftCircle
            size={30}
            style={{ cursor: "pointer", marginRight: 130 }}
            onClick={() => props.setState("table")}
          />
        </div>
        <MultiStepProgressBar
          page={page}
          onPageNumberClick={(value) => {
            setPage(parseInt(value));
          }}
        />
      </div>

      <div>
        {page === 1 ? (
          <Assessment
            userinfo={props.userinfo}
            setPage={setPage}
            editPrompt={props.editPrompt}
            setO1Str={setO1Str}
            setAssessmentStr={setAssessmentStr}
            questions={questions}
            demoAnswers={demoAnswers}
            setQuestions={setQuestions}
            setDemoAnswers={setDemoAnswers}
            setEditPrompt={props.setEditPrompt}
            msgList={msgList}
            setMsgList={setMsgList}
            setNumber={setNumber}
            updateMsg={updateMsg}
          />
        ) : page === 2 ? (
          <Analysis
            userinfo={props.userinfo}
            setPage={setPage}
            editPrompt={props.editPrompt}
            questions={questions}
            demoAnswers={demoAnswers}
            assessmentStr={assessmentStr}
            o1Str={o1Str}
            setO1Str={setO1Str}
            setUpdateSections={setUpdateSections}
            setConvStr={setConvStr}
            setContent={setContent}
            setUpdateSectionsEditable={setUpdateSectionsEditable}
            setEditPrompt={props.setEditPrompt}
            msgList={msgList}
            setMsgList={setMsgList}
            setNumber={setNumber}
            updateMsg={updateMsg}
            setAutoSection1Title={setAutoSection1Title}
            setAutoSection1Content={setAutoSection1Content}
          />
        ) : (
          <Report
            userinfo={props.userinfo}
            setPage={setPage}
            editPrompt={props.editPrompt}
            convStr={convStr}
            content={content}
            updateSections={updateSections}
            setUpdateSections={setUpdateSections}
            updateSectionsEditable={updateSectionsEditable}
            setUpdateSectionsEditable={setUpdateSectionsEditable}
            setEditPrompt={props.setEditPrompt}
          />
        )}
      </div>
    </>
  );
}

export default PromptEdit;
