//@ts-nocheck
import { useState, useEffect } from "react";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { EditorState, Modifier } from "draft-js";
import { Button } from "react-bootstrap";

const blockStyleFn = (contentBlock) => {
  return "blockContetStyle";
};

const InsertOption = ({ editorState, onChange }) => {
  const addLetter = (letters: string) => {
    const contentState = Modifier.replaceText(
      editorState.getCurrentContent(),
      editorState.getSelection(),
      letters,
      editorState.getCurrentInlineStyle()
    );
    onChange(EditorState.push(editorState, contentState, "insert-characters"));
  };

  return (
    <div>
      <Button
        variant="warning"
        style={{
          fontSize: 13,
          padding: "5px 10px",
          fontFamily: "Muli-SemiBold",
          color: "#567a73",
          marginLeft: 5,
          marginRight: 5,
        }}
        onClick={() => addLetter("referral_link")}
      >
        referral_link
      </Button>
      <Button
        variant="warning"
        style={{
          fontSize: 13,
          padding: "5px 10px",
          fontFamily: "Muli-SemiBold",
          color: "#567a73",
          marginLeft: 5,
          marginRight: 5,
        }}
        onClick={() => addLetter("contact_name")}
      >
        contact_name
      </Button>
      <Button
        variant="warning"
        style={{
          fontSize: 13,
          padding: "5px 10px",
          fontFamily: "Muli-SemiBold",
          color: "#567a73",
          marginLeft: 5,
          marginRight: 5,
        }}
        onClick={() => addLetter("reward_page")}
      >
        reward_page
      </Button>
    </div>
  );
};

const CustomWidthOption = ({
  editorState,
  editorWidthValue,
  setEditorWidthValue,
}) => {
  const [widthValue, setWidthValue] = useState(editorWidthValue);

  useEffect(() => {
    const elements = document.getElementsByClassName("blockContetStyle");
    if (widthValue) {
      for (let i = 0; i < elements.length; i++) {
        elements[i].style.width = widthValue + "px";
      }
    } else {
      for (let i = 0; i < elements.length; i++) {
        elements[i].style.width = "100%";
      }
    }
    setEditorWidthValue(widthValue);
  }, [widthValue, editorState]);

  return (
    <input
      type="number"
      placeholder="width"
      value={widthValue}
      style={{
        fontFamily: "Muli-Regular",
        fontSize: 13,
        padding: 5,
        width: 85,
        borderRadius: 6,
        border: "1px solid rgb(2, 23, 48)",
        marginBottom: 7,
      }}
      onChange={(e) => setWidthValue(e.target.value)}
    />
  );
};

const EditorComponent = ({
  editorState,
  setEditorState,
  editorWidthValue,
  setEditorWidthValue,
  hasInsertOption = true,
}) => {
  const onChange = async (value) => {
    setEditorState(value);
  };

  return (
    <div>
      <Editor
        editorState={editorState}
        toolbarClassName="editor-toolbar"
        wrapperClassName="editor-wrapper"
        editorClassName="editor-content"
        onEditorStateChange={(value) => {
          onChange(value);
        }}
        stripPastedStyles
        blockStyleFn={blockStyleFn}
        toolbarCustomButtons={[
          <InsertOption />,
          <CustomWidthOption
            editorState={editorState}
            editorWidthValue={editorWidthValue}
            setEditorWidthValue={setEditorWidthValue}
          />,
        ]}
        toolbar={{
          options: [
            "inline",
            "blockType",
            "fontSize",
            "fontFamily",
            "list",
            "textAlign",
            "colorPicker",
            "link",
            "embedded",
            "emoji",
            "image",
            "remove",
            "history",
          ],
        }}
      />
    </div>
  );
};
export default EditorComponent;
