//@ts-nocheck
import { useState, useEffect } from "react";
import {
  BsX,
  BsQuestionCircle,
  BsPencilFill,
  BsCheckLg,
  BsChevronDown,
  BsChevronRight
} from "react-icons/bs";
import { Button, Popover, OverlayTrigger } from "react-bootstrap";
import Reorder from "react-reorder";
import move from "lodash-move";
import { BeatLoader } from "react-spinners";

import { getChatCompletion, savePrompt } from "../../../api/post";
import AccordianDiv from "../../../components/accordianDiv";
import ButtonEle from "../../../components/buttonEle";
import { randomStringRef, isJsonString } from "../../../service";
import ModalEle from "../../../components/modalEle";
import MsgListObj from "../promptDummy.json";

function Assessment(props: any) {
  const [promptId, setPromptId] = useState(randomStringRef(6) + "promptV2");
  const [promptName, setPromptName] = useState("");
  const [promptDescription, setPromptDescription] = useState("");
  const [promptInstruction, setPromptInstruction] = useState("");
  const [reportMsg, setReportMsg] = useState("");
  const [interimMsg, setInterimMsg] = useState("");

  const [systemMsgJosh, setSystemMsgJosh] = useState("");
  const [promptStrJosh, setPromptStrJosh] = useState("");
  const [assessmentSysMsg, setAssessmentSysMsg] = useState("");
  const [assessmentUserMsg, setAssessmentUserMsg] = useState("");

  const [numberOfQA, setNumberOfQA] = useState<any>(3);
  const [qaSysmsg, setQaSysmsg] = useState("");
  const [qaUsermsg, setQaUsermsg] = useState("");
  const [demoA, setDemoA] = useState("");
  const [q, setQ] = useState("");
  const [descriptionSysmsg, setDescriptionSysmsg] = useState(
    "Your goal is to generate tool descriptions based on the name of the tool."
  );
  const [descriptionUsermsg, setDescriptionUsermsg] = useState("");

  const [showName, setShowName] = useState(true);
  const [showDescription, setShowDescription] = useState(true);
  const [showInstruction, setShowInstruction] = useState(false);
  const [showThankyou, setShowThankyou] = useState(false);
  const [showInterim, setShowInterim] = useState(false);
  const [openCreateQA, setOpenCreateQA] = useState(false);
  const [openQuestion, setOpenQuestion] = useState(false);

  const [qEdit, setQEdit] = useState<boolean[]>([]);

  const [promptLoading, setPromptLoading] = useState(false);
  const [loading, setLoading] = useState(false);

  const [err, setErr] = useState("");

  useEffect(() => {
    if (err) {
      setTimeout(() => {
        setErr("");
      }, 3000);
    }
  }, [err]);

  useEffect(() => {
    if (props.editPrompt) {
      setPromptName(props.editPrompt.name);
      setPromptDescription(props.editPrompt.description);
      setPromptInstruction(
        props.editPrompt.instruction
          ? props.editPrompt.instruction
          : "Complete the assessment and use the generated report to guide your classes."
      );

      setQEdit(Array(props.questions.length).fill(false));

      setSystemMsgJosh(props.editPrompt.systemMsg);
      setPromptId(props.editPrompt.id);

      setReportMsg(
        props.editPrompt.reportMsg
          ? props.editPrompt.reportMsg
          : "Thanks for completing the assessment! Here’s your customized report."
      );
      setInterimMsg(
        props.editPrompt.interimMsg
          ? props.editPrompt.interimMsg
          : "Take a deep breath and exhale while your report is being generated."
      );
    } else {
      setPromptId(randomStringRef(6) + "promptV2");
      setPromptInstruction(
        "Complete the assessment and use the generated report to guide your classes."
      );
      setReportMsg(
        "Thanks for completing the assessment! Here’s your customized report."
      );
      setInterimMsg(
        "Take a deep breath and exhale while your report is being generated."
      );
    }
  }, [JSON.stringify(props.editPrompt)]);

  useEffect(() => {
    const list = JSON.parse(props.msgList);
    for (let i = 0; i < list.length; i++) {
      if (list[i].title === "Tool Description Generator") {
        setDescriptionSysmsg(list[i].sys);
        setDescriptionUsermsg(list[i].user);
      } else if (list[i].title === "Question Generator") {
        setQaSysmsg(list[i].sys);
        setQaUsermsg(list[i].user);
      } else if (list[i].title === "Analysis Prompt System Message Generator") {
        setAssessmentSysMsg(list[i].sys);
        setAssessmentUserMsg(list[i].user);
      } else if (list[i].title === "Analysis Prompt") {
        setPromptStrJosh(list[i].user);
      } else {
      }
    }
  }, [props.msgList]);

  const onReorderQuestion = (e, from, to) => {
    props.setQuestions(move(props.questions, from, to));
    props.setDemoAnswers(move(props.demoAnswers, from, to));
  };

  const enterQuestion = (e: any) => {
    if (e.key === "Enter") {
      addQuery();
    }
  };

  const addQuery = () => {
    if (q && demoA) {
      props.setQuestions((current) => [...current, q]);
      props.setDemoAnswers((current) => [...current, demoA]);
      setQEdit((current) => [...current, false]);
      setQ("");
      setDemoA("");
      setOpenQuestion(false);
    }
  };

  const removeQuery = (index: number) => {
    let arr = [];
    let demoAnsArr = [];
    let editArr = [];
    for (let i = 0; i < props.questions.length; i++) {
      if (i !== index) {
        arr.push(props.questions[i]);
        editArr.push(qEdit[i]);
        demoAnsArr.push(props.demoAnswers[i]);
      }
    }
    props.setQuestions(arr);
    setQEdit(editArr);
    props.setDemoAnswers(demoAnsArr);
  };

  const getAssessmentPrompt = async () => {
    setSystemMsgJosh("");
    setPromptLoading(true);
    const result: any = await getChatCompletion([
      {
        role: "system",
        content: props.updateMsg(assessmentSysMsg)
      },
      {
        role: "user",
        content: props.updateMsg(assessmentUserMsg)
      }
    ]);

    if (result.data) {
      setErr("");
      const txt = result?.data?.choices?.[0]?.message?.content.trim();

      if (txt) {
        let qaStr = "\n\nBelow is the client assessments for context:";
        for (let k = 0; k < props.questions.length; k++) {
          qaStr += `\n\nQuestion: ${
            MsgListObj.symbol.includes(props.questions[k].substr(-1))
              ? props.questions[k]
              : props.questions[k] + "."
          }\nAnswer: ${
            MsgListObj.symbol.includes(props.demoAnswers[k].substr(-1))
              ? props.demoAnswers[k]
              : props.demoAnswers[k] + "."
          }`;
        }
        let sysJoshStr = txt;
        sysJoshStr += qaStr;
        setSystemMsgJosh(sysJoshStr);
      }
    }
    setPromptLoading(false);
  };

  const createQA = async () => {
    if (numberOfQA < 1) return;

    setLoading(true);
    const res: any = await getChatCompletion([
      {
        role: "system",
        content: props.updateMsg(qaSysmsg)
      },
      {
        role: "user",
        content: props.updateMsg(qaUsermsg)
      }
    ]);

    setLoading(false);
    if (res.data) {
      setErr("");
      const txt = res?.data?.choices?.[0]?.message?.content.trim();
      let questionsArr = [...props.questions];
      let answerArr = [...props.demoAnswers];

      if (txt) {
        if (isJsonString(txt)) {
          for (let k = 0; k < JSON.parse(txt).length; k++) {
            questionsArr.push(JSON.parse(txt)[k].question);
            answerArr.push(JSON.parse(txt)[k].answer);
          }
          props.setQuestions(questionsArr);
          props.setDemoAnswers(answerArr);
          setOpenCreateQA(false);
          setNumberOfQA(3);
          props.setNumber(3);
        } else {
          createQA();
        }
      }
    } else {
      setErr("Invalid request");
    }
  };

  const createDescription = async () => {
    setPromptDescription("");
    setLoading(true);
    const res: any = await getChatCompletion([
      {
        role: "system",
        content: props.updateMsg(descriptionSysmsg)
      },
      {
        role: "user",
        content: props.updateMsg(descriptionUsermsg)
      }
    ]);

    setLoading(false);
    if (res.data) {
      setErr("");
      const txt = res?.data?.choices?.[0]?.message?.content.trim();

      if (txt) {
        setPromptDescription(txt);
        const saveRes: any = await savePrompt({
          id: promptId,
          name: promptName,
          description: txt,
          email: props.userinfo.email,
          promptType: "josh",
          msgList: props.msgList
        });

        if (saveRes.data) {
          if (saveRes.data.success) {
            if (saveRes.data.prompt) {
              props.setEditPrompt(saveRes.data.prompt);
            }
          } else {
            setErr(saveRes.data.err?.message);
          }
        }
      }
    } else {
      setErr("Invalid request");
    }
  };

  const ai1Result = async (event: any) => {
    event?.preventDefault();
    if (!systemMsgJosh) {
      setErr("Please type system message");
      return;
    }
    if (!promptStrJosh) {
      setErr("Please type user message");
      return;
    }
    setLoading(true);

    const result: any = await getChatCompletion([
      {
        role: "system",
        content: systemMsgJosh
      },
      {
        role: "user",
        content: promptStrJosh
      }
    ]);
    setLoading(false);
    if (result.data) {
      setErr("");
      const txt = result?.data?.choices?.[0]?.message?.content.trim();
      if (txt) {
        props.setAssessmentStr(txt);
        let str = txt + "\n\nThe following is the survey given to the user:";

        for (let k = 0; k < props.questions.length; k++) {
          str += `\n\nQuestion: ${
            MsgListObj.symbol.includes(props.questions[k].substr(-1))
              ? props.questions[k]
              : props.questions[k] + "."
          }\nAnswer: ${
            MsgListObj.symbol.includes(props.demoAnswers[k].substr(-1))
              ? props.demoAnswers[k]
              : props.demoAnswers[k] + "."
          }`;
        }
        props.setO1Str(str);
        const res: any = await savePrompt({
          id: promptId,
          name: promptName,
          description: promptDescription,
          instruction: promptInstruction,
          email: props.userinfo.email,
          questions: JSON.stringify(props.questions),
          demoAnswers: JSON.stringify(props.demoAnswers),
          clientAnalysis: str,
          systemMsg: systemMsgJosh,
          advanced: false,
          promptType: "josh",
          reportMsg,
          interimMsg,
          msgList: props.msgList
        });

        if (res.data) {
          if (res.data.success) {
            if (res.data.prompt) {
              props.setEditPrompt(res.data.prompt);
            }
          } else {
            setErr(res.data.err?.message);
          }
        }
        props.setPage(2);
      }
    } else {
      setErr("Invalid request");
    }
  };

  return (
    <div className="v2-edit-content">
      <div style={{ width: "calc(100% - 30px)" }}>
        <div className="accordian-div">
          <div
            className="d-flex justify-content-between cursor-pointer"
            onClick={() => setShowName(!showName)}
          >
            <p className="sub-title my-1">Report Name</p>
            <div>
              {showName ? (
                <BsChevronDown size={12} color="#567a73" />
              ) : (
                <BsChevronRight size={12} color="#567a73" />
              )}
            </div>
          </div>
          <AccordianDiv flag={showName}>
            <div className="intdiv">
              <input
                className="int mx-md-1 my-1"
                style={{
                  minWidth: 300,
                  padding: "4px 7px",
                  width: "calc(100% - 5px)"
                }}
                value={promptName}
                onChange={(e) => {
                  setPromptName(e.target.value);
                  props.setEditPrompt({
                    ...props.editPrompt,
                    id: promptId,
                    name: e.target.value
                  });
                }}
              />
            </div>
          </AccordianDiv>
        </div>
        <div className="accordian-div">
          <div
            className="d-flex justify-content-between cursor-pointer"
            onClick={(e) => {
              if (
                e.target.tagName &&
                e.target.tagName.toLowerCase() === "button"
              ) {
                return;
              } else {
                setShowDescription(!showDescription);
              }
            }}
          >
            <div className="d-md-flex align-items-center">
              <p className="sub-title my-1">Report Description</p>
              <Button
                onClick={() => createDescription()}
                className="btn-ele-prompt mx-md-2"
                disabled={loading}
              >
                {loading ? (
                  <BeatLoader color="#f42f3b" size={5} />
                ) : (
                  <>Generate</>
                )}
              </Button>
            </div>
            <div>
              {showDescription ? (
                <BsChevronDown size={12} color="#567a73" />
              ) : (
                <BsChevronRight size={12} color="#567a73" />
              )}
            </div>
          </div>
          <AccordianDiv flag={showDescription}>
            <div className="intdiv">
              <textarea
                className="int mx-md-1 my-1"
                style={{ padding: "4px 7px", width: "calc(100% - 5px)" }}
                value={promptDescription}
                onChange={(e) => setPromptDescription(e.target.value)}
                rows={5}
              />
            </div>
          </AccordianDiv>
        </div>
        <div className="accordian-div">
          <div
            className="d-flex justify-content-between cursor-pointer"
            onClick={() => setShowInstruction(!showInstruction)}
          >
            <p className="sub-title my-1">Report Instructions</p>
            <div>
              {showInstruction ? (
                <BsChevronDown size={12} color="#567a73" />
              ) : (
                <BsChevronRight size={12} color="#567a73" />
              )}
            </div>
          </div>
          <AccordianDiv flag={showInstruction}>
            <div className="intdiv">
              <textarea
                className="int mx-md-1 my-1"
                style={{ padding: "4px 7px", width: "calc(100% - 5px)" }}
                value={promptInstruction}
                onChange={(e) => setPromptInstruction(e.target.value)}
                rows={3}
              />
            </div>
          </AccordianDiv>
        </div>
        <div className="accordian-div">
          <div
            className="d-flex justify-content-between cursor-pointer"
            onClick={() => setShowThankyou(!showThankyou)}
          >
            <p className="sub-title my-1">Report Thank-you Message</p>
            <div>
              {showThankyou ? (
                <BsChevronDown size={12} color="#567a73" />
              ) : (
                <BsChevronRight size={12} color="#567a73" />
              )}
            </div>
          </div>
          <AccordianDiv flag={showThankyou}>
            <div className="intdiv">
              <textarea
                className="int mx-md-1 my-1"
                style={{ padding: "4px 7px", width: "calc(100% - 5px)" }}
                value={reportMsg}
                onChange={(e) => setReportMsg(e.target.value)}
                rows={3}
              />
            </div>
          </AccordianDiv>
        </div>
        <div className="accordian-div">
          <div
            className="d-flex justify-content-between cursor-pointer"
            onClick={() => setShowInterim(!showInterim)}
          >
            <p className="sub-title my-1">Report Interim message</p>
            <div>
              {showInterim ? (
                <BsChevronDown size={12} color="#567a73" />
              ) : (
                <BsChevronRight size={12} color="#567a73" />
              )}
            </div>
          </div>
          <AccordianDiv flag={showInterim}>
            <div className="intdiv">
              <textarea
                className="int mx-md-1 my-1"
                style={{ padding: "4px 7px", width: "calc(100% - 5px)" }}
                value={interimMsg}
                onChange={(e) => setInterimMsg(e.target.value)}
                rows={3}
              />
            </div>
          </AccordianDiv>
        </div>
      </div>
      <div className="d-md-flex align-items-center" style={{ marginTop: 20 }}>
        <OverlayTrigger
          rootClose
          placement="right"
          overlay={
            <Popover>
              <Popover.Body style={{ fontFamily: "Muli-Regular" }}>
                Create questions and make the assessment. Your analysis will be
                created using the answers to these questions.
              </Popover.Body>
            </Popover>
          }
        >
          <p className="sub-title m-0">
            Client Survey
            <button
              className="border-0 bg-transparent"
              style={{ marginRight: 28 }}
            >
              <BsQuestionCircle color="#567a73" size={14} />
            </button>
          </p>
        </OverlayTrigger>
        <div className="mx-md-3 flex">
          <Button
            onClick={() => setOpenCreateQA(true)}
            className="btn-ele-prompt flex"
            style={{ marginRight: 20 }}
          >
            Generate
          </Button>
          <Button
            onClick={() => setOpenQuestion(true)}
            className="btn-ele-prompt"
          >
            Add Question
          </Button>
        </div>
      </div>
      <div>
        <Reorder
          reorderId="questions-list"
          reorderGroup="reorder-group"
          component="div"
          placeholderClassName="placeholder"
          draggedClassName="dragged"
          lock="horizontal"
          holdTime={50}
          touchHoldTime={50}
          mouseHoldTime={20}
          onReorder={onReorderQuestion}
          autoScroll={true}
          disabled={qEdit.includes(true) ? true : false}
          disableContextMenus={true}
          placeholder={<div className="custom-placeholder" />}
        >
          {props.questions &&
            props.questions.map((item: string, index: number) => (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  fontFamily: "Muli-Regular",
                  color: "#567a73",
                  marginTop: "0.25rem",
                  cursor: "move",
                  padding: "3px 5px",
                  borderRadius: 5
                }}
                key={index}
              >
                <div style={{ width: "calc(100% - 80px)" }}>
                  <div className="intdiv mt-0 d-flex">
                    <div>Q: </div>
                    {qEdit[index] ? (
                      <textarea
                        className="int"
                        value={item}
                        rows={2}
                        style={{
                          marginLeft: 10
                        }}
                        onChange={(e) => {
                          let qArr = [...props.questions];
                          qArr[index] = e.target.value;
                          props.setQuestions(qArr);
                        }}
                      ></textarea>
                    ) : (
                      item
                    )}
                  </div>
                  <div className="intdiv mt-1 d-flex">
                    <div>A:</div>
                    {qEdit[index] ? (
                      <textarea
                        className="int"
                        value={
                          props.demoAnswers[index]
                            ? props.demoAnswers[index]
                            : ""
                        }
                        rows={2}
                        style={{
                          marginLeft: 10
                        }}
                        onChange={(e) => {
                          let aArr = [...props.demoAnswers];
                          aArr[index] = e.target.value;
                          props.setDemoAnswers(aArr);
                        }}
                      ></textarea>
                    ) : props.demoAnswers[index] ? (
                      props.demoAnswers[index]
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                <div
                  style={{
                    width: 80,
                    display: "flex",
                    alignItems: "center"
                  }}
                >
                  {qEdit[index] ? (
                    <BsCheckLg
                      className="p-3"
                      size={60}
                      style={{ cursor: "pointer" }}
                      color="#20c997"
                      onClick={() => {
                        let qEditArr = [...qEdit];
                        qEditArr[index] = false;
                        setQEdit(qEditArr);
                      }}
                    />
                  ) : (
                    <BsPencilFill
                      size={50}
                      style={{ cursor: "pointer" }}
                      className="p-3"
                      onClick={() => {
                        let qEditArr = [...qEdit];
                        qEditArr[index] = true;
                        setQEdit(qEditArr);
                      }}
                    />
                  )}

                  <BsX
                    size={40}
                    style={{ cursor: "pointer" }}
                    className="p-1"
                    onClick={() => {
                      if (qEdit[index]) {
                        let qEditArr = [...qEdit];
                        qEditArr[index] = false;
                        setQEdit(qEditArr);
                      } else {
                        removeQuery(index);
                      }
                    }}
                  />
                </div>
              </div>
            ))}
        </Reorder>
      </div>

      <div className="d-md-flex align-items-center mt-4">
        <OverlayTrigger
          rootClose
          placement="top"
          overlay={
            <Popover>
              <Popover.Body style={{ fontFamily: "Muli-Regular" }}>
                The goal of this prompt is to generate an Analysis of the user.
              </Popover.Body>
            </Popover>
          }
        >
          <p className="sub-title m-0">
            Analysis Prompt{" "}
            <button className="border-0 bg-transparent">
              <BsQuestionCircle color="#567a73" size={14} />
            </button>
          </p>
        </OverlayTrigger>
        <div>
          <Button
            onClick={() => getAssessmentPrompt()}
            className="btn-ele-prompt"
          >
            Generate
          </Button>
        </div>
      </div>
      <div className="mt-4">
        {promptLoading && <BeatLoader color="#f42f3b" size={12} />}
        <div className="intdiv">
          <p className="lable">System message</p>
          <textarea
            id="smsg"
            className="int"
            value={systemMsgJosh}
            rows={8}
            onChange={(e) => {
              setSystemMsgJosh(e.target.value);
            }}
            disabled={promptLoading}
          ></textarea>
        </div>
        <div className="intdiv">
          <p className="lable">User message</p>
          <textarea
            id="prompt"
            className="int"
            value={promptStrJosh}
            rows={5}
            onChange={(e) => {
              setPromptStrJosh(e.target.value);
            }}
            disabled={promptLoading}
          ></textarea>
        </div>
      </div>
      {err && <p className="err">{err}</p>}
      <div className="mt-4">
        <ButtonEle title="generate" handle={ai1Result} />
      </div>
      {loading && (
        <div className="loading">
          <BeatLoader color="#f42f3b" size={12} />
        </div>
      )}

      <ModalEle
        name="autoCreateQA"
        open={openCreateQA}
        setOpen={setOpenCreateQA}
        title="Create Q/A"
        handle={createQA}
        buttonTitle="Generate"
        loading={loading}
      >
        <div className="intdiv mt-0">
          <p className="lable">how many questions do you want to add?</p>
          <input
            className="int"
            value={numberOfQA}
            onChange={(e) => {
              {
                setNumberOfQA(parseInt(e.target.value) || "");
                props.setNumber(parseInt(e.target.value) || "");
              }
            }}
          />
        </div>
      </ModalEle>

      <ModalEle
        name="manualCreateQA"
        open={openQuestion}
        setOpen={setOpenQuestion}
        title="Client Survey"
        handle={addQuery}
        buttonTitle="Save"
        loading={loading}
      >
        <div className="intdiv mt-0">
          <input
            className="int mt-0"
            placeholder="question"
            value={q}
            type="text"
            onChange={(e) => setQ(e.target.value)}
            onKeyUp={(e) => enterQuestion(e)}
          />
        </div>
        <div className="intdiv mt-0">
          <input
            className="int"
            placeholder="answer (demo)"
            value={demoA}
            type="text"
            onChange={(e) => setDemoA(e.target.value)}
            onKeyUp={(e) => enterQuestion(e)}
          />
        </div>
      </ModalEle>
    </div>
  );
}

export default Assessment;
