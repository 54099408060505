import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { BeatLoader } from "react-spinners";
import { EditorState, convertToRaw } from "draft-js";
import draftToHtml from "draftjs-to-html";
import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";

import Layout from "../components/layout";
import TextField from "../components/textField";
import EditorComponent from "../components/editor";
import ButtonEle from "../components/buttonEle";

function CampaignBuilder(props: any) {
  const params = useParams();

  const [err, setErr] = useState("");
  const [success, setSuccess] = useState("");
  const [status, setStatus] = useState(false);
  const [editEmailStatus, setEditEmailStatus] = useState(false);

  const [whenFirstWebclass, setWhenFirstWebclass] = useState(
    new Date(
      new Date().toLocaleString("en-US", {
        timeZone: "America/Los_Angeles"
      })
    )
  );
  const [dayNumber, setDayNumber] = useState<number | null>(null);
  const [classNumber, setClassNumber] = useState<number | null>(null);

  const [firstContent, setFirstContent] = useState("");
  const [secondContent, setSecondContent] = useState("");
  const [thirdContent, setThirdContent] = useState("");

  const initialState = () => EditorState.createEmpty();
  const [editorState1, setEditorState1] = useState(initialState);
  const [editorState2, setEditorState2] = useState(initialState);
  const [editorState3, setEditorState3] = useState(initialState);
  const [editorWidthValue, setEditorWidthValue] = useState<number>(600);

  useEffect(() => {
    let htmlStr = `<div style="width: ${
      editorWidthValue ? editorWidthValue - 100 + "px" : "100%"
    };">`;
    htmlStr += draftToHtml(convertToRaw(editorState1.getCurrentContent()));
    htmlStr += `</div>`;
    setFirstContent(htmlStr);
  }, [editorState1, editorWidthValue]);

  useEffect(() => {
    let htmlStr = `<div style="width: ${
      editorWidthValue ? editorWidthValue - 100 + "px" : "100%"
    };">`;
    htmlStr += draftToHtml(convertToRaw(editorState2.getCurrentContent()));
    htmlStr += `</div>`;
    setSecondContent(htmlStr);
  }, [editorState2, editorWidthValue]);

  useEffect(() => {
    let htmlStr = `<div style="width: ${
      editorWidthValue ? editorWidthValue - 100 + "px" : "100%"
    };">`;
    htmlStr += draftToHtml(convertToRaw(editorState3.getCurrentContent()));
    htmlStr += `</div>`;
    setThirdContent(htmlStr);
  }, [editorState3, editorWidthValue]);

  useEffect(() => {
    if (err || success) {
      setTimeout(() => {
        setSuccess("");
        setErr("");
      }, 4000);
    }
  }, [err, success]);

  useEffect(() => {
    if (dayNumber && dayNumber > 30) {
      setErr("Please insert number of days between 1 to 30");
      setDayNumber(0);
    }
  }, [dayNumber]);

  const generateHandle = async () => {
    if (!classNumber || !dayNumber) return;
    setStatus(true);
  };
  const sendEmailHandle = async () => {};

  return (
    <Layout userinfo={props.userinfo}>
      <div className="sub-container">
        <div className="content">
          <div
            className="d-md-flex justify-content-between"
            style={{ marginBottom: 20 }}
          >
            <h2 className="title">campaign builder</h2>
          </div>

          <div className="intdiv">
            <p className="lable">
              When is the first webclass?
              <span className="mx-2">*</span>
              (Pacific Time Zone)
            </p>
            <Datetime
              timeConstraints={{
                minutes: {
                  min: 0,
                  step: 5,
                  max: 55
                }
              }}
              value={whenFirstWebclass}
              onChange={(value) =>
                setWhenFirstWebclass(new Date(value.toString()))
              }
            />
          </div>
          <TextField
            title="How many days until the next class?"
            required={true}
            type="number"
            value={dayNumber}
            setValue={(value: number | null) => {
              if (!value) setDayNumber(value);
              if (value && value >= 0) setDayNumber(Math.floor(value));
            }}
          />
          <TextField
            title="How many classes do you want in this campaign?"
            required={true}
            type="number"
            value={classNumber}
            setValue={(value: number | null) => {
              if (!value) setClassNumber(value);
              if (value && value >= 0) setClassNumber(Math.floor(value));
            }}
          />
          <div style={{ marginTop: 40 }}>
            <ButtonEle title="Generate" handle={generateHandle} />
          </div>
          {status && (
            <>
              <div className="mt-5">
                <div className="intdiv">
                  <p className="lable">November 6, 2023 10:00amPT</p>
                </div>
                <div className="intdiv">
                  <p className="lable">November 13, 2023 10:00amPT</p>
                </div>
                <div className="intdiv">
                  <p className="lable">November 20, 2023 10:00amPT </p>
                </div>
                <div className="intdiv">
                  <p className="lable">November 27, 2023 10:00amPT</p>
                </div>
                <div className="intdiv">
                  <p className="lable">December 4, 2023 10:00amPT</p>
                </div>
              </div>
              <div className="d-flex align-items-center">
                <h2 className="title mt-4">Reminder Emails</h2>
                <div className="mx-3 mt-4">
                  <button
                    type="button"
                    className="btn btn-success"
                    style={{ fontSize: 14, fontFamily: "Muli-SemiBold" }}
                    onClick={() => setEditEmailStatus(!editEmailStatus)}
                  >
                    {editEmailStatus ? "hide emails" : "edit emails"}
                  </button>
                </div>
              </div>
              {editEmailStatus && (
                <>
                  <div className="intdiv mt-3">
                    <p className="lable">
                      Webclass signup email
                      <span>*</span>
                    </p>

                    <EditorComponent
                      editorState={editorState1}
                      setEditorState={setEditorState1}
                      editorWidthValue={editorWidthValue}
                      setEditorWidthValue={setEditorWidthValue}
                      hasInsertOption={false}
                    />
                  </div>
                  <div className="intdiv">
                    <p className="lable">
                      Webclass 1 hour reminder email
                      <span>*</span>
                    </p>
                    <EditorComponent
                      editorState={editorState2}
                      setEditorState={setEditorState2}
                      editorWidthValue={editorWidthValue}
                      setEditorWidthValue={setEditorWidthValue}
                      hasInsertOption={false}
                    />
                  </div>
                  <div className="intdiv">
                    <p className="lable">
                      Webclass 15 minute reminder email
                      <span>*</span>
                    </p>
                    <EditorComponent
                      editorState={editorState3}
                      setEditorState={setEditorState3}
                      editorWidthValue={editorWidthValue}
                      setEditorWidthValue={setEditorWidthValue}
                      hasInsertOption={false}
                    />
                  </div>
                  <div style={{ marginTop: 40 }}>
                    <ButtonEle title="Send Email" handle={sendEmailHandle} />
                  </div>
                </>
              )}
            </>
          )}
          {props.loading && (
            <div className="loading">
              <BeatLoader color="#f42f3b" size={12} />
            </div>
          )}
          {err && <p className="err">{err}</p>}
          {success && <p className="success">{success}</p>}
        </div>
      </div>
    </Layout>
  );
}

export default CampaignBuilder;
