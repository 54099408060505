//@ts-nocheck
import { useState, useEffect } from "react";
import Reorder from "react-reorder";
import move from "lodash-move";
import { Button, Popover, OverlayTrigger } from "react-bootstrap";
import { BsX, BsQuestionCircle, BsPencilFill, BsCheckLg } from "react-icons/bs";
import { BeatLoader } from "react-spinners";
import { randomStringRef, isJsonString } from "../../../service";
import { getChatCompletion, savePrompt } from "../../../api/post";

import ButtonEle from "../../../components/buttonEle";
import ModalEle from "../../../components/modalEle";

function Analysis(props: any) {
  const [promptId, setPromptId] = useState(randomStringRef(6) + "promptV2");
  const [analysisSysMsg, setAnalysisSysMsg] = useState("");
  const [analysisUserMsg, setAnalysisUserMsg] = useState("");

  const [basicSections, setBasicSections] = useState<
    { t: string; p: string; o: string }[]
  >([]);
  const [autoSection1Title, setAutoSection1Title] = useState("Intro");
  const [autoSection1Content, setAutoSection1Content] = useState(
    "Write a paragraph that provides an overview about the benefits of being a better coach"
  );
  const [numberOfAutoSections, setNumberOfAutoSections] = useState<any>(3);
  const [autoSectionSysmsg, setAutoSectionSysmsg] = useState("");
  const [autoSectionUsermsg, setAutoSectionUsermsg] = useState("");
  const [section, setSection] = useState<{ t: string; p: string; o: string }>({
    t: "",
    p: "",
    o: "The output of this section should be 1-2 paragraphs"
  });

  const [o1StrEditable, setO1StrEditable] = useState(false);
  const [sEdit, setSEdit] = useState<boolean[]>([]);
  const [openAutoCreateSection, setOpenAutoCreateSection] = useState(false);
  const [openSection, setOpenSection] = useState(false);

  const [err, setErr] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (err) {
      setTimeout(() => {
        setErr("");
      }, 3000);
    }
  }, [err]);

  useEffect(() => {
    if (props.editPrompt) {
      setPromptId(props.editPrompt.id);

      if (
        props.editPrompt.basicSections &&
        isJsonString(props.editPrompt.basicSections)
      ) {
        const sectionsArr = JSON.parse(props.editPrompt.basicSections);
        let arr = [];
        for (let i = 0; i < sectionsArr.length; i++) {
          const t = sectionsArr[i].t ? sectionsArr[i].t : "";
          const p = sectionsArr[i].p ? sectionsArr[i].p : "";
          const o = sectionsArr[i].to
            ? sectionsArr[i].o
            : "The output of this section should be 1-2 paragraphs.";
          arr.push({ t, p, o });
        }

        setBasicSections(arr);
        setSEdit(
          Array(JSON.parse(props.editPrompt.basicSections).length).fill(false)
        );
      }
    } else {
      setPromptId(randomStringRef(6) + "promptV2");
    }
  }, [JSON.stringify(props.editPrompt)]);

  useEffect(() => {
    const list = JSON.parse(props.msgList);
    for (let i = 0; i < list.length; i++) {
      if (list[i].title === "Create Report Section Generator") {
        setAutoSectionSysmsg(list[i].sys);
        setAutoSectionUsermsg(list[i].user);
      } else if (list[i].title === "Create Report Generator 1") {
        setAnalysisSysMsg(list[i].sys);
        setAnalysisUserMsg(list[i].user);
      }
    }
  }, [props.msgList]);

  const onReorderSection = (e, from, to) => {
    setBasicSections(move(basicSections, from, to));
  };

  const enterSection = (e: any) => {
    if (e.key === "Enter") {
      addSection();
    }
  };

  const addSection = () => {
    if (section.t && section.p && section.o) {
      let arr = [...basicSections];
      arr.push(section);

      setBasicSections(arr);
      setSEdit((current) => [...current, false]);
      setSection({ t: "", p: "", o: "" });
      setOpenSection(false);
    }
  };

  const removeSection = (index: number) => {
    let arr = [];
    let editArr = [];
    for (let i = 0; i < basicSections.length; i++) {
      if (i !== index) {
        arr.push(basicSections[i]);
        editArr.push(sEdit[i]);
      }
    }
    setBasicSections(arr);
    setSEdit(editArr);
  };

  const autoCreateSection = async () => {
    if (numberOfAutoSections < 1) return;

    setLoading(true);
    const res: any = await getChatCompletion([
      {
        role: "system",
        content: props.updateMsg(autoSectionSysmsg)
      },
      {
        role: "user",
        content: props.updateMsg(autoSectionUsermsg)
      }
    ]);

    setLoading(false);
    if (res.data) {
      setErr("");
      const txt = res?.data?.choices?.[0]?.message?.content.trim();
      if (txt) {
        if (isJsonString(txt)) {
          let arr = [...basicSections];
          for (let i = 0; i < JSON.parse(txt).length; i++) {
            if (i >= numberOfAutoSections) break;

            arr.push({
              t: JSON.parse(txt)[i].Title,
              p: JSON.parse(txt)[i].Instruction,
              o: "The output of this section should be 1-2 paragraphs."
            });
            setSEdit((current) => [...current, false]);
          }

          const saveRes: any = await savePrompt({
            id: promptId,
            basicSections: JSON.stringify(arr),
            email: props.userinfo.email,
            msgList: props.msgList
          });

          if (saveRes.data) {
            if (saveRes.data.success) {
              if (saveRes.data.prompt) {
                props.setEditPrompt(saveRes.data.prompt);
              }
            } else {
              setErr(saveRes.data.err?.message);
            }
          }
        }
        setOpenAutoCreateSection(false);
        setNumberOfAutoSections(3);
        props.setNumber(3);
      }
    } else {
      setErr("Invalid request");
    }
  };

  const ai2Result = async (event: any) => {
    event?.preventDefault();

    if (basicSections.length === 0) {
      setErr("Please create sections");
      return;
    }

    setLoading(true);

    let resultArr: any = [];
    let str = "";
    let contentArr: any = [];

    let symbol = ["!", ".", "?"];
    for (let i = 0; i < basicSections.length; i++) {
      let usermsg = "";
      for (let k = 0; k < i; k++) {
        usermsg += `Here is section ${k + 1} of the report: ${
          resultArr[k].t
        }: ${
          symbol.includes(resultArr[k].p.substr(-1))
            ? resultArr[k].p
            : resultArr[k].p + "."
        } `;
      }
      usermsg += `\n${props.updateMsg(analysisUserMsg)}`;

      usermsg = usermsg.replace(/({section1 title})/g, basicSections[i].t);
      usermsg = usermsg.replace(
        /({section1 instruction})/g,
        basicSections[i].p
      );
      usermsg = usermsg.replace(/({section1 output})/g, basicSections[i].o);

      const result: any = await getChatCompletion([
        {
          role: "system",
          content: props.updateMsg(analysisSysMsg)
        },
        {
          role: "user",
          content: usermsg
        }
      ]);

      if (result.data) {
        setErr("");
        const txt = result?.data?.choices?.[0]?.message?.content.trim();
        if (txt) {
          resultArr.push({ t: basicSections[i].t, p: txt });
          str += `${basicSections[i].t}\n${txt}\n`;
          contentArr.push({ title: basicSections[i].t, body: txt });
        }
      } else {
        setErr("Invalid request");
        break;
      }
    }

    props.setConvStr(str);
    props.setContent(contentArr);
    props.setUpdateSections(resultArr);
    props.setUpdateSectionsEditable(
      Array(resultArr.length).fill({
        t: false,
        p: false
      })
    );
    await savePrompt({
      id: promptId,
      email: props.userinfo.email,
      basicSections: JSON.stringify(basicSections),
      report: JSON.stringify(resultArr),
      msgList: props.msgList
    });

    props.setEditPrompt({
      ...props.editPrompt,
      basicSections: JSON.stringify(basicSections),
      report: JSON.stringify(resultArr)
    });

    setLoading(false);
    props.setPage(3);
  };

  return (
    <div className="v2-edit-content">
      <div className="pb-1">
        <OverlayTrigger
          rootClose
          placement="right"
          overlay={
            <Popover>
              <Popover.Body style={{ fontFamily: "Muli-Regular" }}>
                This is the analysis created by ChatGPT using the Assessment.
              </Popover.Body>
            </Popover>
          }
        >
          <p className="sub-title m-0" style={{ width: 160 }}>
            Client Analysis
            <button className="border-0 bg-transparent">
              <BsQuestionCircle color="#567a73" size={14} />
            </button>
          </p>
        </OverlayTrigger>

        <div className="intdiv d-flex">
          <textarea
            className="int"
            value={props.o1Str}
            rows={12}
            onChange={(e) => {
              props.setO1Str(e.target.value);
            }}
            disabled={!o1StrEditable}
          ></textarea>
          {o1StrEditable ? (
            <BsCheckLg
              className="m-2"
              size={20}
              style={{ cursor: "pointer" }}
              color="#20c997"
              onClick={() => setO1StrEditable(false)}
            />
          ) : (
            <BsPencilFill
              color="rgb(86, 122, 115)"
              style={{ cursor: "pointer" }}
              className="m-2"
              onClick={() => setO1StrEditable(true)}
            />
          )}
        </div>
        <div className="mt-4">
          <div className="mt-4 mb-2 d-md-flex align-items-center">
            <div className="d-flex align-items-center">
              <p className="sub-title m-0">Create Report Section </p>
              <div className="pb-1">
                <OverlayTrigger
                  rootClose
                  placement="right"
                  overlay={
                    <Popover>
                      <Popover.Body style={{ fontFamily: "Muli-Regular" }}>
                        Sections are parts of a page. For example, a blog post
                        might have 3 sections: an intro, overview, and
                        conclusion. Each section is created using a prompt.
                      </Popover.Body>
                    </Popover>
                  }
                >
                  <button className="border-0 bg-transparent">
                    <BsQuestionCircle color="#567a73" />
                  </button>
                </OverlayTrigger>
              </div>
            </div>
            <Button
              onClick={() => setOpenAutoCreateSection(true)}
              className="btn-ele-prompt flex"
              style={{ marginRight: 10 }}
            >
              Generate
            </Button>
            <Button
              className="btn-ele-prompt"
              onClick={() => setOpenSection(true)}
              style={{ cursor: "pointer" }}
            >
              Add Section
            </Button>
          </div>
          <div style={{ marginTop: 20 }}>
            <div>
              <Reorder
                reorderId="sections-list"
                reorderGroup="reorder-group"
                component="div"
                placeholderClassName="placeholder"
                draggedClassName="dragged"
                lock="horizontal"
                holdTime={50}
                touchHoldTime={50}
                mouseHoldTime={20}
                onReorder={onReorderSection}
                autoScroll={true}
                disabled={sEdit.includes(true) ? true : false}
                disableContextMenus={true}
                placeholder={<div className="custom-placeholder" />}
              >
                {basicSections &&
                  basicSections.map(
                    (item: { t: string; p: string }, index: number) => (
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          fontFamily: "Muli-Regular",
                          color: "#567a73",
                          marginTop: "0.25rem",
                          cursor: "move",
                          padding: "3px 5px",
                          borderRadius: 5,
                          border: "1px solid #a1d4c6"
                        }}
                        key={index}
                      >
                        <div
                          className="intdiv mt-0"
                          style={{
                            width: "calc(100% - 80px)",
                            fontFamily: "Muli-Regular",
                            color: "#567a73"
                          }}
                        >
                          <div className="d-md-flex">
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                                whiteSpace: "nowrap",
                                width: "100%"
                              }}
                            >
                              <div style={{ marginRight: 33 }}>
                                Section Title:{" "}
                              </div>
                              <div style={{ width: "100%" }}>
                                {sEdit[index] ? (
                                  <textarea
                                    id={`${index}section-title-pair`}
                                    className="int"
                                    value={item.t}
                                    rows={2}
                                    style={{
                                      marginLeft: 10
                                    }}
                                    onChange={(e) => {
                                      let sArr = [...basicSections];
                                      sArr[index].t = e.target.value;
                                      setBasicSections(sArr);
                                    }}
                                  ></textarea>
                                ) : (
                                  item.t
                                )}
                              </div>
                            </div>
                          </div>
                          <div className="d-md-flex">
                            <div
                              style={{
                                display: "flex",
                                alignItems: "left",
                                justifyContent: "space-between",
                                whiteSpace: "nowrap",
                                paddingTop: 10,
                                width: "100%"
                              }}
                            >
                              <div style={{ marginRight: 10 }}>
                                Section Prompt:{" "}
                              </div>
                              <div
                                style={{
                                  whiteSpace: "normal",
                                  alignItems: "center",
                                  textAlign: "left",
                                  width: "100%"
                                }}
                              >
                                {sEdit[index] ? (
                                  <textarea
                                    id={`${index}section-prompt-pair`}
                                    className="int"
                                    value={item.p}
                                    rows={5}
                                    style={{
                                      marginLeft: 10
                                    }}
                                    onChange={(e) => {
                                      let sArr = [...basicSections];
                                      sArr[index].p = e.target.value;
                                      setBasicSections(sArr);
                                    }}
                                  ></textarea>
                                ) : (
                                  item.p
                                )}
                              </div>
                            </div>
                          </div>
                          <div className="d-md-flex">
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                                whiteSpace: "nowrap",
                                width: "100%",
                                paddingTop: 10
                              }}
                            >
                              <div style={{ marginRight: 13 }}>
                                Section Output:{" "}
                              </div>
                              <div style={{ width: "100%" }}>
                                {sEdit[index] ? (
                                  <textarea
                                    id={`${index}section-output-pair`}
                                    className="int"
                                    value={item.o}
                                    rows={2}
                                    style={{
                                      marginLeft: 10
                                    }}
                                    onChange={(e) => {
                                      let sArr = [...basicSections];
                                      sArr[index].o = e.target.value;
                                      setBasicSections(sArr);
                                    }}
                                  ></textarea>
                                ) : (
                                  item.o
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          style={{
                            width: 80,
                            display: "flex",
                            alignItems: "center"
                          }}
                        >
                          {sEdit[index] ? (
                            <BsCheckLg
                              className="p-3"
                              size={60}
                              style={{ cursor: "pointer" }}
                              color="#20c997"
                              onClick={() => {
                                let sEditArr = [...sEdit];
                                sEditArr[index] = false;
                                setSEdit(sEditArr);
                              }}
                            />
                          ) : (
                            <BsPencilFill
                              size={50}
                              style={{ cursor: "pointer" }}
                              className="p-3"
                              onClick={() => {
                                let sEditArr = [...sEdit];
                                sEditArr[index] = true;
                                setSEdit(sEditArr);
                              }}
                            />
                          )}
                          <BsX
                            size={40}
                            className="p-1"
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              if (sEdit[index]) {
                                let sEditArr = [...sEdit];
                                sEditArr[index] = false;
                                setSEdit(sEditArr);
                              } else {
                                removeSection(index);
                              }
                            }}
                          />
                        </div>
                      </div>
                    )
                  )}
              </Reorder>
            </div>
          </div>
          {err && <p className="err">{err}</p>}
          <div className="mt-4">
            <ButtonEle
              title="create report"
              handle={ai2Result}
              style={{ margin: 5 }}
            />
          </div>
          {loading && (
            <div className="loading">
              <BeatLoader color="#f42f3b" size={12} />
            </div>
          )}
        </div>
      </div>
      <ModalEle
        name="autoCreateSection"
        open={openAutoCreateSection}
        setOpen={setOpenAutoCreateSection}
        title="Auto create section"
        handle={autoCreateSection}
        buttonTitle="Generate"
        loading={loading}
      >
        <div className="intdiv">
          <p className="lable">What is the name of the first section?</p>
          <input
            className="int"
            value={autoSection1Title}
            onChange={(e) => {
              {
                setAutoSection1Title(e.target.value);
                props.setAutoSection1Title(e.target.value);
              }
            }}
          />
        </div>
        <div className="intdiv">
          <p className="lable">What content do you want in this section?</p>
          <textarea
            className="int"
            value={autoSection1Content}
            onChange={(e) => {
              {
                setAutoSection1Content(e.target.value);
                props.setAutoSection1Content(e.target.value);
              }
            }}
            rows={4}
          />
        </div>
        <div className="intdiv">
          <p className="lable">How many sections do you want in the report?</p>
          <input
            className="int"
            value={numberOfAutoSections}
            onChange={(e) => {
              {
                setNumberOfAutoSections(parseInt(e.target.value) || "");
                props.setNumber(parseInt(e.target.value) || "");
              }
            }}
          />
        </div>
      </ModalEle>

      <ModalEle
        name="manualCreateSection"
        open={openSection}
        setOpen={setOpenSection}
        title="Create Report Section"
        handle=""
        buttonTitle=""
        loading={loading}
      >
        <div
          className="p-3"
          style={{
            display: "flex",
            alignItems: "flex-end",
            border: "1px solid #a1d4c6",
            borderRadius: 6
          }}
        >
          <div
            className="intdiv mt-0"
            style={{
              width: "calc(100% - 40px)"
            }}
          >
            <textarea
              id="section-title"
              className="int"
              value={section.t}
              placeholder="Section title. For example, Overview, Summary, Introduction. This will be the name for the section."
              rows={6}
              onChange={(e) => {
                setSection({ ...section, t: e.target.value });
              }}
              onKeyUp={(e) => enterSection(e)}
            ></textarea>
            <textarea
              id="section-prompt"
              className="int"
              value={section.p}
              placeholder="Section Prompt. Write a prompt to generate content for the section. For example, 'Create a paragraph that summarizes the analysis and encourages them to join a coaching webclass.'"
              rows={6}
              onChange={(e) => {
                setSection({ ...section, p: e.target.value });
              }}
              onKeyUp={(e) => enterSection(e)}
            ></textarea>
            <textarea
              id="section-output"
              className="int"
              value={section.o}
              placeholder=""
              rows={3}
              onChange={(e) => {
                setSection({ ...section, o: e.target.value });
              }}
              onKeyUp={(e) => enterSection(e)}
            ></textarea>
          </div>
          <div
            style={{
              width: "30px",
              height: "30px",
              cursor: "pointer",
              marginLeft: "10px",
              paddingBottom: "35px"
            }}
            onClick={() => addSection()}
          >
            <BsCheckLg size={30} color="#20c997" />
          </div>
        </div>
      </ModalEle>
    </div>
  );
}

export default Analysis;
