import { Link, useNavigate } from "react-router-dom";
import ManImg from "../assets/imgs/man.png";
import Img1 from "../assets/imgs/target.png";
import Img2 from "../assets/imgs/Gift.png";
import Img3 from "../assets/imgs/Robot.png";
import ButtonEle from "../components/buttonEle";
import { Image, Button } from "react-bootstrap";
import ReferralImage from "../assets/imgs/referralImage1.png";
import Footbar from "../components/footbar";
import LogoImg from "../assets/imgs/sign-right-img.png";

function Home(props: any) {
  const handle = () => {
    props.isUserData("click");
  };
  return (
    <>
      <div className="landing-container">
        <div className="landing-header d-md-flex">
          <Image src={LogoImg} width={150} alt="logo" />
          <div className="my-2">
            <Button className="login-btn" onClick={() => handle()}>
              Log in
            </Button>
            {/* <ButtonEle
            title="Book a demo"
            style={{ width: "auto", marginLeft: 30 }}
          /> */}
          </div>
        </div>
        <div className="landing-content">
          <div className="section1 d-md-flex justify-content-between">
            <div>
              <h1>Boost Your Sales with Viral Marketing</h1>
              <p className="mt-4">
                Use Our Smart Referral Campaigns to Transform Customers into Brand Ambassadors.
              </p>
              {/* <ButtonEle title="Book a demo" style={{ width: "auto" }} /> */}
            </div>
            <div>
              <img src={ReferralImage} style={{ width: 500 }} />
            </div>
          </div>
          <div className="section2">
            <h3>Empower Brand Ambassadors to Become Your Sales Force.</h3>

            <div
              className="d-md-flex justify-content-between"
              style={{ marginTop: 50 }}
            >
              <div style={{ padding: "0 30px" }}>
                <div style={{ textAlign: "center" }}>
                  <div className="img-div d-flex justify-content-center align-items-center">
                    <Image src={Img1} />
                  </div>
                  <h4>Smart Referral Campaigns</h4>
                  <p>
                    Our platform enables seamless conversion of your loyal followers into active promoters, 
                    expanding your customer base organically.
                  </p>
                </div>
              </div>
              <div style={{ padding: "0 30px" }}>
                <div style={{ textAlign: "center" }}>
                  <div className="img-div d-flex justify-content-center align-items-center">
                    <Image src={Img2} />
                  </div>
                  <h4>Tiered Rewards</h4>
                  <p>
                    We incentivize your new ambassadors by offering exclusive content and exciting giveaways, 
                    encouraging them to actively promote your brand and bring in new customers. 
                  </p>
                </div>
              </div>
              <div style={{ padding: "0 30px" }}>
                <div style={{ textAlign: "center" }}>
                  <div className="img-div d-flex justify-content-center align-items-center">
                    <Image src={Img3} />
                  </div>
                  <h4>AI-Powered Outreach</h4>
                  <p>
                    Say goodbye to generic emails: our AI personalizes each
                    message and optimizes your conversion rate.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="footbar-home">
        2024 Sentives, All rights reserved.{" "}
        <Link
          to="/tos"
          style={{ color: "rgb(2, 23, 48)", fontFamily: "Muli-Bold" }}
        >
          Terms
        </Link>{" "}
        and{" "}
        <Link
          to="/policy"
          style={{ color: "rgb(2, 23, 48)", fontFamily: "Muli-Bold" }}
        >
          Privacy
        </Link>
      </div>
    </>
  );
}

export default Home;
