// @ts-nocheck
import { useState } from "react";
import { ethers } from "ethers";
import { Button } from "react-bootstrap";
import { entropyToMnemonic } from "@ethersproject/hdnode";
import { BsEye, BsEyeSlash, BsDownload } from "react-icons/bs";

function EthWallet() {
  const [address, setAddress] = useState("");
  const [seedPhrase, setSeedPhrase] = useState<string[]>([]);
  const [privateKey, setPrivateKey] = useState("");
  const [showKey, setShowKey] = useState(false);
  const [downContent, setDownContent] = useState("");

  const generateWallet = async () => {
    let txt = "";
    const mnemonic = entropyToMnemonic(ethers.randomBytes(32));
    const wallet = ethers.Wallet.fromPhrase(mnemonic);
    if (wallet) {
      setAddress(wallet.address);
      setSeedPhrase(mnemonic.split(" "));
      setPrivateKey(wallet.privateKey);
      txt += `Wallet Address:\n ${wallet.address}\n\n Seed phrase:\n ${mnemonic}\n\n Private key: \n ${wallet.privateKey}`;
      setDownContent(txt);
    }
  };

  const downloadTxtFile = () => {
    const element = document.createElement("a");
    const file = new Blob([downContent], { type: "text/plain" });
    element.href = URL.createObjectURL(file);
    element.download = "wallet.txt";
    document.body.appendChild(element); // Required for this to work in FireFox
    element.click();
  };

  return (
    <div
      style={{
        background: "#f5f6f8",
        width: "100%",
        minHeight: "100vh"
      }}
    >
      <div className="eth_content">
        <div className="intdiv">
          <p className="lable">Wallet address:</p>
          <label>{address}</label>
        </div>
        <div className="intdiv">
          <p className="lable">Seed phrase:</p>
          <label>
            {seedPhrase.map((item: string, index: number) => (
              <span className="phrase" key={index}>
                {item}
              </span>
            ))}
          </label>
        </div>
        <div className="intdiv">
          <div className="d-flex justify-content-between">
            <p className="lable">
              Private key:
              {privateKey && (
                <>
                  {showKey ? (
                    <BsEyeSlash onClick={() => setShowKey(false)} />
                  ) : (
                    <BsEye onClick={() => setShowKey(true)} />
                  )}
                </>
              )}
            </p>
            {downContent && (
              <div>
                <BsDownload onClick={() => downloadTxtFile()} />
              </div>
            )}
          </div>
          {showKey && <label>{privateKey}</label>}
        </div>
        <div className="mt-4 text-center">
          <Button variant="success" onClick={() => generateWallet()}>
            Generate Wallet
          </Button>
        </div>
      </div>
    </div>
  );
}

export default EthWallet;
